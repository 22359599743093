body {
    background-color: #212937;
    color: white;
    font-family: Arial, sans-serif;
}

.container {
    text-align: center;
    margin-top: 100px;
}

.header {
    font-size: 20px;
    text-align: center;
    margin-top: 20px;
}

.ukrainian-word {
    background: linear-gradient(135deg, #015bbb, #fed500);
    -webkit-background-clip: text;
    color: transparent;
}


.drawer {
    background-color: #212937;
}

.modalContent {
    background-color: #212937;
    width: 30%;
    margin: 10% auto;
    padding: 20px;
    border: 1px solid #3bd671;
    border-radius: 10px;
    color: white;
}

@media screen and (max-width: 767px) {
    .container {
        margin-top: 15px;
    }

    .header {
        font-size: 24px;
        margin-top: 15px;
    }

    .drawerBox {
        width: 100%;
    }

    .modalContent {
        width: 90%;
        margin: 30% auto;
        padding: 15px;
    }
}